import { Route, Routes, BrowserRouter } from "react-router-dom"
import AuthScreen from "overrides/theme/entel/base/screens/LoginScreen/LoginScreen"
import { PublicRoute, PrivateRoute } from "router/RouteType"
import PrivateContainer from "containers/PrivateContainer"
// import Multiclient from 'components/Multiclient';
import LoadScreen from "screens/LoadScreen/LoadScreen"

const PublicRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="login/*"
          element={
            <PublicRoute>
              <Routes>
                <Route path="/*" element={<AuthScreen />} />
              </Routes>
            </PublicRoute>
          }
        />
        <Route
          path="callback"
          element={
            <PublicRoute>
              <LoadScreen />
            </PublicRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <PrivateContainer />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default PublicRouter
