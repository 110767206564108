export const SOURCE_TYPE = [
  { id: 1, label: "Virtual", value: "virtual" },
  { id: 2, label: "Fisico", value: "physical" },
]

export const TYPE_DINAMIC = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

const parsedDinamicDataOnSave = (field: any) => {
  let values: any = {}
  ;(field || []).map((item: any) => {
    if (item?.key?.includes(".")) {
      let [key1, key2] = item?.key?.split(".")
      if (values.hasOwnProperty(key1)) {
        values[key1] = { ...values[key1], [key2]: assignDataType(item.type, item.value) }
      } else {
        Object.assign(values, { [key1]: { [key2]: assignDataType(item.type, item.value) } })
      }
      return true
    }
    Object.assign(values, { [item.key]: assignDataType(item.type, item.value) })
    return true
  })
  return values
}

const assignDataType = (type: any, value: any) => {
  try {
    switch (type?.value) {
      case "array-string":
        return value && value?.split("+")
      case "number":
        return Number(value) || 0
      case "string":
        return value?.toString() || JSON.stringify(value)
      default:
        return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

export const TYPE = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    name,
    alias,
    type,
    physicalLink,
    location,
    safety,
    ranking,
    storePickup,
    enabled,
    contact,
    custom,
    volumeCapacity,
    dispatchCapacity,
    schedule,
    crossdock,
    workingSessions,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    OIM: {
      // 1
      id,
      name,
      alias: [alias],
      type: type?.value,
      physicalLink: type?.value === "virtual" ? physicalLink : "",
      // 2
      location: parsedDinamicDataOnSave(location),
      // 3
      safety: Number(safety),
      ranking: Number(ranking),
      storePickup,
      enabled,
      // 4
      contact,
      // 5
      custom: parsedDinamicDataOnSave(custom),
    },
    OLM: {
      // 1
      id,
      name,
      alias: [alias],
      // 6
      volumeCapacity: volumeCapacity?.map((vc: any) => ({
        daysOfWeek: vc?.daysOfWeek.map((day: any) => Number(day?.value)),
        overCapacity: Number(vc?.overCapacity),
        capacity: Number(vc?.capacity),
      })),
      // 7
      dispatchCapacity: dispatchCapacity?.map((dp: any) => ({
        daysOfWeek: dp?.daysOfWeek.map((day: any) => Number(day?.value)),
        deliveryType: dp?.deliveryType?.value,
        productType: dp.productType && dp.productType?.split(","),
        overCapacity: Number(dp?.overCapacity),
        capacity: Number(dp?.capacity),
      })),
      // 8
      schedule: schedule?.map((sch: any) => ({
        deliveryType: sch?.deliveryType?.value,
        shippingType: sch?.shippingType ? sch?.shippingType?.map((shp: any) => shp?.value) : [],
        productType: sch.productType && sch.productType?.split(","),
        leadtime: {
          value: Number(sch?.leadtime?.value),
          unit: sch?.leadtime?.unit?.value,
        },
        slots: sch?.slots?.map((slot: any) => {
          return {
            from: slot?.from?.value,
            to: slot?.to?.value,
            label: slot?.label,
          }
        }),
      })),
      // 9
      crossdock: crossdock.map((crss: any) => ({
        productType: crss.productType && crss.productType?.split(","),
        value: Number(crss?.value),
        unit: crss?.unit?.value,
      })),
      // 10
      workingSessions: (workingSessions || []).map((ws: any) => {
        let wsData = {
          productType: ws.productType && ws.productType?.split(","),
          capacity: Number(ws?.capacity),
          daysOfWeek: ws?.daysOfWeek.map((day: any) => Number(day?.value)),
          from: ws.from?.value,
          to: ws.to?.value,
        }
        if (Boolean(ws.enable_locktime)) {
          wsData = Object.assign(wsData, {
            lockTime: {
              from: ws.lockTime?.from?.value,
              to: ws.lockTime?.to?.value,
            },
          })
        }
        return wsData
      }),
      // 11
      workingTime: workingTime?.map((wt: any) => ({
        daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day?.value)),
        from: wt?.from?.value,
        to: wt?.to?.value,
      })),
      // 12
      calendarSettings: {
        timezone: calendarSettings?.timezone?.value,
        nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => Number(day?.value)) || [],
        nonWorkingDates: calendarSettings?.nonWorkingDates || [],
      },
    },
  }

  return data
}
