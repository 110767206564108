import __t from "./i18n/es"

class JsonViewer extends HTMLElement {
  constructor() {
      super();
      this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
      this.render();
      this.update();
  }

  static get observedAttributes() {
      return ['data'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
      if (name === 'data' && oldValue !== newValue) {
          this.update();
      }
  }

  update() {
      const data = this.getAttribute('data');
      try {
          const json = JSON.parse(data);
          if (this.shadowRoot.querySelector('#output')) {
            this.shadowRoot.querySelector('#output').innerHTML = this.renderJson(json, 0);
          }
      } catch (e) {
          this.shadowRoot.querySelector('#output').innerHTML = '<div class="error">Invalid JSON</div>';
      }
  }

  renderJson(json, level) {
      if (json === null) {
          return `<span class="null">null</span>`;
      } else if (typeof json === 'object') {
          if (Array.isArray(json)) {
              return this.renderArray(json, level);
          } else {
              return this.renderObject(json, level);
          }
      } else if (typeof json === 'string') {
          return `<span class="string">"${json}"</span>`;
      } else if (typeof json === 'number') {
          return `<span class="number">${json}</span>`;
      } else if (typeof json === 'boolean') {
          return `<span class="boolean">${json}</span>`;
      }
  }

  renderObject(obj, level) {
      let html = '<div class="object">';
      for (const [key, value] of Object.entries(obj)) {
          html += `
              <div class="item">
                  <span class="key">${__t(key)}:</span>
                  <div class="value-container">
                      ${this.renderJson(value, level + 1)}
                  </div>
              </div>`;
      }
      html += '</div>';
      return html;
  }

  renderArray(arr, level) {
      let html = '<div class="array">';
      arr.forEach((item, index) => {
          html += `
              <div class="item">
                  <span class="key hide">${index}:</span>
                  <div class="value-container">
                      ${this.renderJson(item, level + 1)}
                  </div>
              </div>`;
      });
      html += '</div>';
      return html;
  }

  render() {
      this.shadowRoot.innerHTML = `
          <style>
              :host {
                  display: block;
                  padding: 16px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  background: #f9f9f9;
                  font-family: Arial, sans-serif;
              }
              .object, .array {
                  margin-left: 20px;
              }
              .item {
                  margin: 4px 0;
              }
              .key {
                  font-weight: bold;
              }
              .value {
                  color: #333;
              }
              .null {
                  color: #a52a2a;
              }
              .string {
                  color: #007f00;
              }
              .number {
                  color: #0000ff;
              }
              .boolean {
                  color: #ff4500;
              }
              .error {
                  color: red;
              }
              .value-container {
                  padding-left: 20px;
              }
              .hide {
                  display: none;
              }
          </style>
          <div id="output"></div>
      `;
  }
}

customElements.define("json-viewer", JsonViewer)
