import { Fragment, useContext, useEffect } from "react"
import Spinner from "components/Core/Spinner"
import background_login from "assets/img/background_login.jpg"
import logo_xmonitor_login from "assets/img/logo_xmonitor_login.svg"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import AuthContext from "store/context/PublicContext"
import { logInAction } from "store/actions/publicActions"

const LoadScreen = () => {
  const navigate = useNavigate()
  const { user, isAuthenticated, isLoading } = useAuth0()
  const { dispatch } = useContext(AuthContext)

  useEffect(() => {
    if (Boolean(isAuthenticated) && Boolean(user) && !Boolean(isLoading)) {
      dispatch(
        logInAction({
          id: user?.nickname,
          name: user?.name,
          role: user?.omnixRoles && user?.omnixRoles[0]?.role,
          email: user?.email,
          token: user?.authentication?.token,
          custom: {
            ...user?.userProfile,
            jobPosition: user?.omnixRoles && user?.omnixRoles[0]?.jobPosition,
          },
        }),
      )
      navigate("/dashboard")
    }
    if (!Boolean(isAuthenticated) && !Boolean(isLoading)) {
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, isLoading])

  return (
    <Fragment>
      <Spinner />
      <div className="relative w-screen h-screen grid place-items-center">
        <img src={background_login} alt="backgroung_login" className="absolute w-full h-full object-cover -z-10" />
        <div className="relative w-4/5 h-3/4 lg:h-auto md:w-96 lg:min-w-[30rem] flex flex-col items-center justify-center gap-10 px-4 py-8 md:py-12 lg:py-16 bg-[#FFFFFFE6] rounded-xl">
          <img src={logo_xmonitor_login} alt="logo" className="w-3/4 md:w-72 z-10" />
          <div className="w-full flex flex-col gap-4 p-6 lg:p-10">
            <p className="py-1 font- text-xs">Espere...</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default LoadScreen
