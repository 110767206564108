export const LOCATION_TYPE = [
  { id: 1, label: "Texto", value: "text" },
  { id: 2, label: "Coordenadas", value: "coordinates" },
]

export const parsed_body_to_send = (_data: any) => {
  const { criteria0, criteria1, criteria2, criterias } = _data

  let data = {
    criteria0,
    criteria1,
    criteria2,
  }
  criterias?.map((cri: any, i: number) => {
    data = Object.assign(data, { [`criteria${i + 3}`]: cri?.value })
    return true
  })

  return data
}
