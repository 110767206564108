import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import location_values from "utils/forms_default/location_values"
import schemaValidationLocations from "utils/yup/locations_schema"
import { useFieldArray } from "react-hook-form"
import { FormLocation } from "components/FormEntities"
import { useParams } from "react-router-dom"

const EditScreen = () => {
  const { location_id } = useParams()
  const { control, errors, watch } = useForm({
    defaultValues: location_values,
    schema: schemaValidationLocations,
  })
  const criterias_field_form = useFieldArray({ control, name: "criterias" })

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de ubicaciones", href: "/locations" },
          { name: "Edición de ubicación", href: `/locations/${location_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de ubicación" string_id={location_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormLocation control={control} errors={errors} watch={watch} criterias_field_form={criterias_field_form} />
      </form>
    </div>
  )
}

export default EditScreen
