import { useEffect, useRef, useState } from "react"
import {
  Breadcrumb,
  Select,
  ButtonGroup,
  TitleScreen,
  Table,
  AddButton,
  EditButton,
  Modal,
  TrashButton,
} from "components/Core"
import useForm from "hooks/useForm"
import { Controller, useFieldArray } from "react-hook-form"
import schemaValidationBusiness from "utils/yup/business_schema"
import { Coverages } from "components/DinamicFormSections"
import schemaValidationCoverages from "utils/yup/coverages_schema"
import coverage_values from "utils/forms_default/coverage_values"
import get_client_color from "utils/get_client_color"
import cn from "classnames"
// import { CHANNELS, SHIPPING_TYPES, DELIVERY_TYPES, SOURCES, COURIERS } from "constants/COVERAGE_CONSTANTS"
import { useParams } from "react-router-dom"
import useAreasScreenUtils from "hooks/useAreasScreenUtils"

const DetailScreen = () => {
  const { CHANNELS, DELIVERY_TYPES, SOURCES, COURIERS, SHIPPING_TYPES } = useAreasScreenUtils()

  const { area_id } = useParams()
  const _color = get_client_color()
  const modal_ref = useRef<any>(null)
  const [tableCoverages, setTableCoverages] = useState([])

  const { control, watch, getValues } = useForm({
    schema: schemaValidationBusiness,
  })

  const { control: controlCoverages, errors: errorsCoverages } = useForm({
    defaultValues: coverage_values,
    schema: schemaValidationCoverages,
  })

  const working_time_field_form = useFieldArray({ control: controlCoverages, name: "workingTime" })
  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  const handle_click_open_working_time = () => {
    modal_ref?.current?.open()
  }

  const handle_click_close_working_time = () => {
    modal_ref.current?.close()
  }

  const remove_all_working_time = () => {
    working_time_field_form.replace([])
  }

  const getCombinations = (arrays: any) => {
    if (arrays.length === 1) {
      return arrays[0].map((item: any) => [item])
    }

    const firstArray = arrays[0]
    const remainingArrays = arrays.slice(1)
    const combinations: any = getCombinations(remainingArrays)

    const result = []

    for (const item of firstArray) {
      for (const combination of combinations) {
        result.push([item, ...combination])
      }
    }

    return result
  }

  const [watch_channel, watch_shippingType, watch_deliveryType, watch_mfc, watch_couriers] = watch([
    "channel",
    "shippingType",
    "deliveryType",
    "mfc",
    "couriers",
  ])

  useEffect(() => {
    if (watch_channel?.length) {
      setTableCoverages(getCombinations([getValues("channel")]))
    }
    if (watch_channel?.length && watch_shippingType?.length) {
      setTableCoverages(getCombinations([getValues("channel"), getValues("shippingType")]))
    }
    if (watch_channel?.length && watch_shippingType?.length && watch_deliveryType?.length) {
      setTableCoverages(getCombinations([getValues("channel"), getValues("shippingType"), getValues("deliveryType")]))
    }
    if (watch_channel?.length && watch_shippingType?.length && watch_deliveryType?.length && watch_mfc?.length) {
      setTableCoverages(
        getCombinations([getValues("channel"), getValues("shippingType"), getValues("deliveryType"), getValues("mfc")]),
      )
    }
    if (
      watch_channel?.length &&
      watch_shippingType?.length &&
      watch_deliveryType?.length &&
      watch_mfc?.length &&
      watch_couriers?.length
    ) {
      setTableCoverages(
        getCombinations([
          getValues("channel"),
          getValues("shippingType"),
          getValues("deliveryType"),
          getValues("mfc"),
          getValues("couriers"),
        ]),
      )
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_channel, watch_shippingType, watch_deliveryType, watch_mfc, watch_couriers])

  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de zonas", href: "/areas" },
          { name: "Detalle de zona", href: `/areas/${area_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de zona #${area_id}`} />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-6 py-2">
          <ButtonGroup />
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          <div className="w-full flex flex-col lg:flex-row gap-6">
            <div className="w-full flex flex-col lg:flex-row lg:w-3/5 gap-6 rounded-lg border bg-white shadow-md p-4">
              <div className="w-full flex flex-col items-start gap-2">
                <h4 className="text-xl">Comuna</h4>
                <div className="w-full flex flex-col">
                  <p className="text-base">Providencia</p>
                </div>
              </div>
              <div className="w-full flex flex-col items-start gap-2">
                <h4 className="text-xl">a. Zona de exclusión</h4>
                <div className="w-full flex flex-col">
                  <p className="text-base">#Providencia Sur</p>
                  <p className="text-xs font-light italic">
                    Zona dinámica: No hay entregas de Lunes a Sábado de 14:00-20:00 hrs.
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col items-start gap-2">
                <h4 className="text-xl">Zona de inclusión</h4>
                <div className="w-full flex flex-col">
                  <p className="text-base">#Plaza Las Lilas</p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col lg:w-2/5 gap-6 rounded-lg border bg-white shadow-md p-4">
              <div className="w-full flex flex-row">
                <h4 className="text-xl">Horario Delivery</h4>
                <EditButton onClick={handle_click_open_working_time} label="Editar" />
              </div>
              <div className="w-full flex flex-row">
                <ul className="list-inside list-disc text-sm italic">
                  <li>Lunes a Jueves de 8:00 a 20:00 hrs</li>
                  <li>Viernes de 8:00 a 14:00 hrs</li>
                  <li>Sábados de 8:00 a 12:00 hrs</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col lg:flex-row gap-6">
            <div className="w-full flex flex-col lg:flex-row gap-6 rounded-lg border bg-white shadow-md p-4">
              <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
                <h4 className="font-medium">Canal</h4>
                <Controller
                  name="channel"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Seleccionar" multi options={CHANNELS} onChange={onChange} value={value} />
                  )}
                />
              </div>
              <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
                <h4 className="font-medium">Tipo de envío</h4>
                <Controller
                  name="shippingType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Seleccionar" multi options={SHIPPING_TYPES} onChange={onChange} value={value} />
                  )}
                />
              </div>
              <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
                <h4 className="font-medium">Tipo de despacho</h4>
                <Controller
                  name="deliveryType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Seleccionar" multi options={DELIVERY_TYPES} onChange={onChange} value={value} />
                  )}
                />
              </div>
              <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
                <h4 className="font-medium">MFC</h4>
                <Controller
                  name="mfc"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Seleccionar" multi options={SOURCES} onChange={onChange} value={value} />
                  )}
                />
              </div>
              <div className="w-full flex flex-col lg:w-1/5 items-start justify-center gap-2">
                <h4 className="font-medium">Couriers</h4>
                <Controller
                  name="couriers"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Seleccionar" multi options={COURIERS} onChange={onChange} value={value} />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                <Table.HeadCell>Canal</Table.HeadCell>
                <Table.HeadCell>Tipo de envío</Table.HeadCell>
                <Table.HeadCell>Tipo de despacho</Table.HeadCell>
                <Table.HeadCell>MFC</Table.HeadCell>
                <Table.HeadCell>Couriers</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {tableCoverages?.length ? (
                  tableCoverages.map((row: any, rowIndex: number) => {
                    return (
                      <Table.Row key={rowIndex}>
                        {row.map((col: any) => {
                          return <Table.Cell>{col.label}</Table.Cell>
                        })}
                      </Table.Row>
                    )
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell>Sin resultados</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </form>
      <Modal className="w-full h-4/5 lg:w-9/12 px-20 py-8 overflow-auto" ref={modal_ref}>
        <div className="w-full flex flex-row items-center justify-between p-2">
          <h4 className="font-medium text-xl">Horario Delivery</h4>
          {working_time_field_form.fields.length > 0 && (
            <TrashButton onClick={remove_all_working_time} label="Eliminar todo" />
          )}
        </div>
        <div className="w-full border-b border-[#919191]"></div>
        <div className="w-full flex flex-col">
          {working_time_field_form.fields.map((workingTimeItem, i) => {
            return (
              <Coverages.WorkingTimeFields
                key={workingTimeItem.id}
                index={i}
                working_time={working_time_field_form}
                control={controlCoverages}
                errors={errorsCoverages}
                title="Custom"
              />
            )
          })}

          <div
            className={`w-full flex flex-row items-center justify-start ${
              working_time_field_form.fields.length ? "px-16" : "px-0 pt-5"
            }`}
          >
            <AddButton onClick={add_new_working_time} />
          </div>

          {working_time_field_form.fields.length > 0 && (
            <div className="w-full flex flex-row justify-center gap-6 mt-10 lg:mt-20">
              <button
                type="button"
                onClick={handle_click_close_working_time}
                className={cn(
                  "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                  `border_${_color}`,
                  `text_${_color}`,
                )}
              >
                Cerrar
              </button>
              <button
                type="submit"
                onClick={handle_click_close_working_time}
                className={cn(
                  "w-full flex flex-col items-center lg:w-48 p-2 text-center text-base font-semibold hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                  `bg_${_color}`,
                )}
              >
                Aplicar
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default DetailScreen
