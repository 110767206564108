import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationCoverages = yup.object().shape({
  // 1
  id: yup.string().required("required-field"),
  status: yup.boolean(),
  // 2
  routeId: yup.string().required("required-field"),
  courierId: yup.string().required("required-field"),
  // 3
  shippingType: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-shippingType")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  deliveryType: yup_object_select.required("required-field"),
  // 4
  deliveryDays: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-day")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  pickupDays: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-day")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 5
  leadtime: yup.object().shape({
    value: yup.number().integer().min(0).required("required-field"),
    unit: yup_object_select.required("required-field"),
  }),
  // 6
  workingTime: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-day")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        from: yup_object_select.required("required-field"),
        to: yup_object_select.required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-workingday")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 7
  cost: yup
    .array(
      yup.object().shape({
        key: yup.string().required("required-field"),
        value: yup.string().required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-workingday")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
})

export default schemaValidationCoverages
