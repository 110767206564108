export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    status,
    routeId,
    courierId,
    shippingType,
    deliveryType,
    leadtime,
    workingTime,
    pickupDays,
    deliveryDays,
    cost,
  } = _data

  let data = {
    // 1
    id,
    status,
    // 2
    routeId,
    courierId,
    // 3
    shippingType: shippingType ? shippingType?.map((shp: any) => shp?.value) : [],
    deliveryType: deliveryType?.value,
    // 4
    deliveryDays: deliveryDays ? deliveryDays?.map((del_day: any) => del_day?.value) : [],
    pickupDays: pickupDays ? pickupDays?.map((pck_day: any) => pck_day?.value) : [],
    // 5
    leadtime: {
      value: Number(leadtime?.value),
      unit: leadtime?.unit?.value,
    },
    // 6
    workingTime: workingTime?.map((wt: any) => ({
      daysOfWeek: wt?.daysOfWeek.map((day: any) => day?.value),
      from: wt?.from?.value,
      to: wt?.to?.value,
    })),
    // 7
    cost: cost
      ? cost.map((_cost: any) => ({
          [_cost?.key]: _cost?.value,
        }))
      : [],
  }

  return data
}
