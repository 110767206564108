import { Fragment } from "react"
import useForm from "hooks/useForm"
import { Button } from "components/Core"
import schemaValidationLogin from "utils/yup/login_schema"
import NotificationList from "components/Core/Notifications/NotificationList"
import Spinner from "components/Core/Spinner"
import background_login from "assets/img/background_login.jpg"
import logo_xmonitor_login from "assets/img/logo_xmonitor_login.svg"
import { useAuth0 } from "@auth0/auth0-react"

const LoginScreen = () => {
  const { handleSubmit, isSubmitting } = useForm({ schema: schemaValidationLogin })
  const { loginWithRedirect } = useAuth0()

  const onSubmit = () => {
    loginWithRedirect()
  }

  return (
    <Fragment>
      <NotificationList />
      <Spinner />
      <div className="relative w-screen h-screen grid place-items-center">
        <img src={background_login} alt="backgroung_login" className="absolute w-full h-full object-cover -z-10" />
        <div className="relative w-4/5 h-3/4 lg:h-auto md:w-96 lg:min-w-[30rem] flex flex-col items-center justify-center gap-10 px-4 py-8 md:py-12 lg:py-16 bg-[#FFFFFFE6] rounded-xl">
          <img src={logo_xmonitor_login} alt="logo" className="w-3/4 md:w-72 z-10" />
          <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-4 p-6 lg:p-10">
            <Button label={isSubmitting ? "Espere..." : "Ingresar"} disabled={Boolean(isSubmitting)} type="submit" />
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default LoginScreen
