import { AddButton, FormSection, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { Locations } from "components/DinamicFormSections"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IFormLocation {
  control: any
  errors: any
  watch: any
  criterias_field_form: any
}

const FormLocation = ({ control, errors, watch, criterias_field_form }: IFormLocation) => {
  const add_new_criteria = () =>
    criterias_field_form.append({
      type: "",
      value: "",
    })

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection title="Información general" helperText="Información general" number={1}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Id Ubicación" onChange={onChange} value={value} />
            )}
          />
          <Controller
            name="criteria0"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="criteria0" onChange={onChange} value={value} />
            )}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="criteria1"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="criteria1" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="criteria2"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="criteria2" onChange={onChange} value={value} />
              )}
            />
          </div>
          {Boolean(["criteria0", "criteria1", "criteria2"].some((key: string) => Object.keys(errors).includes(key))) ? (
            <BadgeAlertForm />
          ) : null}
        </div>
      </FormSection>
      <FormSection title="Criterias adicionales" helperText="Criterias adicionales" number={2}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {criterias_field_form.fields.map((criteriaItem: any, i: number) => {
            return (
              <Locations.CriteriasFields
                key={criteriaItem.id}
                index={i}
                criterias={criterias_field_form}
                control={control}
                errors={errors}
                watch={watch}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            {criterias_field_form.fields.length >= 2 ? null : <AddButton onClick={add_new_criteria} />}
          </div>
          {Boolean(errors?.criterias) ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormLocation
