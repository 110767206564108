import { Select, TextField, TrashButton, Switch } from "components/Core"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import { TYPE_DINAMIC } from "components/FormEntities/FormSource/source.config"
import { Controller } from "react-hook-form"

interface ILocationFields {
  index: number
  control: any
  watch: any
  location: any
  errors: any
}

const LocationFields = ({ index, control, watch, location, errors }: ILocationFields) => {
  const type = watch(`location[${index}].type`)
  const remove_location = () => location.remove(index)

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex flex-row items-center justify-between p-2">
        <h4 className="">Ubicación {Number(index + 1)}</h4>
        <TrashButton onClick={remove_location} />
      </div>
      <div className="w-full border-b border-[#919191]"></div>
      <Controller
        control={control}
        name={`location[${index}].key`}
        defaultValue={location.fields[index].key}
        render={({ field: { onChange, value } }) => <TextField label="Clave" onChange={onChange} value={value} />}
      />
      <div className="flex flex-row gap-4">
        <Controller
          name={`location[${index}].type`}
          defaultValue={location.fields[index].type}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Tipo" options={TYPE_DINAMIC} onChange={onChange} value={value} />
          )}
        />
        {type?.value === "boolean" ? (
          <Controller
            name={`location[${index}].value`}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <Switch label="Valor" onChange={onChange} checked={value} />}
          />
        ) : (
          <Controller
            control={control}
            name={`location[${index}].value`}
            defaultValue={location.fields[index].value}
            render={({ field: { onChange, value } }) => <TextField label="Valor" onChange={onChange} value={value} />}
          />
        )}
      </div>
      {Boolean(errors?.location && errors?.location[index]) ? <BadgeAlertForm /> : null}
    </div>
  )
}

export default LocationFields
