import { Select, Switch, TextField, TrashButton } from "components/Core"
import { Controller } from "react-hook-form"
import { DAYS_OF_WEEK_NUMBER, HOURS_OF_DAY } from "constants/FORM_CONSTANTS"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IDeliverySlotsFields {
  index: number
  control: any
  deliverySlot: any
  errors: any
}

const DeliverySlotsFields = ({ index, control, deliverySlot, errors }: IDeliverySlotsFields) => {
  const remove_slot = () => deliverySlot.remove(index)

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex flex-row items-center justify-between p-2">
        <h4 className="">Slot de entrega {Number(index + 1)}</h4>
        <TrashButton onClick={remove_slot} />
      </div>
      <div className="w-full border-b border-[#919191]"></div>
      <div className="flex flex-col lg:flex-row gap-4">
        <Controller
          name={`deliverySlots[${index}].dayOfWeek`}
          defaultValue={deliverySlot.fields[index].dayOfWeek}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Dia de la semana" options={DAYS_OF_WEEK_NUMBER} onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <Controller
          name={`deliverySlots[${index}].label`}
          defaultValue={deliverySlot.fields[index].label}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField label="Etiqueta slot" onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="w-full flex flex-row items-center justify-start px-2">
        <h4 className="">Rango de horario </h4>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <Controller
          name={`deliverySlots[${index}].from`}
          defaultValue={deliverySlot.fields[index].from}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Inicio" options={HOURS_OF_DAY} onChange={onChange} value={value} />
          )}
        />
        <Controller
          name={`deliverySlots[${index}].to`}
          defaultValue={deliverySlot.fields[index].to}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select label="Fín" options={HOURS_OF_DAY} onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <Controller
          name={`deliverySlots[${index}].capacity`}
          defaultValue={deliverySlot.fields[index].capacity}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField label="Capacidad" type="number" onChange={onChange} value={value} />
          )}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <Controller
          name={`deliverySlots[${index}].status`}
          defaultValue={deliverySlot.fields[index].status}
          control={control}
          render={({ field: { onChange, value } }) => <Switch label="Habilitado" onChange={onChange} checked={value} />}
        />
      </div>
      {Boolean(errors?.deliverySlots && errors?.deliverySlots[index]) ? <BadgeAlertForm /> : null}
    </div>
  )
}

export default DeliverySlotsFields
