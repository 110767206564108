import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationCapacity = yup.object().shape({
  dateFrom: yup.string().required("required-field"),
  dateTo: yup.string().required("required-field"),
  maxCapacity: yup.string().required("required-field"),
  type: yup_object_select.required("required-field"),
  entityType: yup_object_select.required("required-field"),
  entityIds: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-entity")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  productType: yup.string().nullable(),
  deliveryType: yup_object_select.when(["entityType", "type"], ([entityType, type]: any) => {
    if (entityType?.value === "Source" && type?.value === "dispatch") {
      return yup_object_select.required("required-field")
    }
    return yup.mixed().nullable()
  }),
})

export default schemaValidationCapacity
