import { PiWarningCircleFill } from "react-icons/pi"

const BadgeAlertForm = () => {
  return (
    <div className="w-full grid place-items-center">
      <div className="w-11/12 flex flex-row items-center justify-center gap-2 p-2 bg-[#FFDA9B] rounded-md">
        <PiWarningCircleFill className="text-xl text-[#CE8400]" />
        <p className="text-black text-sm cursor-default">Por favor, verifique los campos anteriores!</p>
      </div>
    </div>
  )
}

export default BadgeAlertForm
