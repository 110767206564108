import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationCouriers = yup.object().shape({
  // 1
  id: yup.string().required("required-field"),
  name: yup.string().required("required-field"),
  alias: yup.string().required("required-field"),
  // 2
  shippingType: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-shippingType")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  deliveryType: yup_object_select.required("required-field"),
  // 3
  costStrategy: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-cost")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  locationStrategy: yup_object_select.required("required-field"),
  // 4
  productType: yup.string().required("required-field"),
  status: yup_object_select,
  channel: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-channel")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 5
  criterias: yup.string().required("required-field"),
  // 6
  dispatchCapacity: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-shippingType")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        overCapacity: yup.number().integer().min(0).required("required-field"),
        capacity: yup.number().integer().min(0).required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 7
  volumeCapacity: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-day")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        overCapacity: yup.number().integer().min(0).required("required-field"),
        capacity: yup.number().integer().min(0).required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 8
  workingTime: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-day")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        from: yup_object_select.required("required-field"),
        to: yup_object_select.required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-workingday")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 9
  calendarSettings: yup.object().shape({
    nonWorkingDays: yup
      .array(yup_object_select)
      .min(1, "you-must-enter-at-least-1-day")
      .required("required-field")
      .typeError("you-must-enter-at-least-one-value"),
    nonWorkingDates: yup.array(yup.string()),
  }),
})

export default schemaValidationCouriers
