import { Breadcrumb, TitleScreen, ButtonGroup } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationCouriers from "utils/yup/couriers_schema"
import courier_values from "utils/forms_default/courier_values"
import { useFieldArray } from "react-hook-form"
import { FormCourier } from "components/FormEntities"
import { parsed_body_to_send } from "components/FormEntities/FormCourier/courier.config"
import useCouriersResources from "hooks/entities/useCouriersResources"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: courier_values,
    schema: schemaValidationCouriers,
  })
  const { set_loading_app, put_upsert_courier_action } = useCouriersResources()

  const volume_capacity_field_form = useFieldArray({ control, name: "volumeCapacity" })
  const dispatch_capacity_field_form = useFieldArray({ control, name: "dispatchCapacity" })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      await put_upsert_courier_action({ body })
      Swal.fire({
        title: "Hecho",
        text: "Courier creado",
        icon: "success",
      })
      set_loading_app(false)
      navigate("/couriers")
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de couriers", href: "/couriers" },
          { name: "Creación de couriers", href: "/couriers/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de couriers" />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCourier
          control={control}
          errors={errors}
          setValue={setValue}
          volume_capacity_field_form={volume_capacity_field_form}
          dispatch_capacity_field_form={dispatch_capacity_field_form}
          working_time_field_form={working_time_field_form}
        />
      </form>
    </div>
  )
}

export default NewScreen
