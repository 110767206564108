enum OMNIX_MODULE {
  OOM = "OOM",
  OIM = "OIM",
  OLM = "OLM",
  OSRM = "OSRM",
  OMA = "OMA",
  OSM = "OSM",
  OITM = "OITM",
  MIDDLEWARE = "MIDDLEWARE",
  LISTENER = "LISTENER",
  DOWNLOAD = "DOWNLOAD",
  PRIORITIZATION = "PRIORITIZATION",
  BFF = "BFF"
}

export default OMNIX_MODULE