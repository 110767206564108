
import { Breadcrumb, TitleScreen, ButtonGroup } from "components/Core"
import { useParams } from "react-router-dom"
import { useFieldArray } from "react-hook-form"
import schemaValidationSources from "utils/yup/sources_schema"
import useForm from "hooks/useForm"
import source_values from "utils/forms_default/source_values"
import { FormSource } from "components/FormEntities"
import { parsed_body_to_send } from "components/FormEntities/FormSource/source.config"

const EditScreen = () => {
  const { source_id } = useParams()
  const { control, errors, watch, handleSubmit, setValue } = useForm({
    defaultValues: source_values,
    schema: schemaValidationSources,
  })

  const location_field_form = useFieldArray({ control, name: "location" })
  const custom_field_form = useFieldArray({ control, name: "custom" })
  const volume_capacity_field_form = useFieldArray({ control, name: "volumeCapacity" })
  const dispatch_capacity_field_form = useFieldArray({ control, name: "dispatchCapacity" })
  const schedule_field_form = useFieldArray({ control, name: "schedule" })
  const crossdock_field_form = useFieldArray({ control, name: "crossdock" })
  const working_sessions_field_form = useFieldArray({ control, name: "workingSessions" })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })

  const on_submit = (data: any) => {
    const body = parsed_body_to_send(data)
    console.log("body", body)
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de sources", href: "/sources" },
          { name: "Detalle de source", href: `/sources/${source_id}` },
          { name: "Editar source", href: `/sources/${source_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title={`Editar source #${source_id}`} />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormSource
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          location_field_form={location_field_form}
          custom_field_form={custom_field_form}
          volume_capacity_field_form={volume_capacity_field_form}
          dispatch_capacity_field_form={dispatch_capacity_field_form}
          schedule_field_form={schedule_field_form}
          crossdock_field_form={crossdock_field_form}
          working_sessions_field_form={working_sessions_field_form}
          working_time_field_form={working_time_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
