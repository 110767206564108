import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import { FormRoute } from "components/FormEntities"
import useForm from "hooks/useForm"
import { useParams } from "react-router-dom"
import route_values from "utils/forms_default/route_values"
import schemaValidationRoutes from "utils/yup/routes_schema"

const EditScreen = () => {
  const { route_id } = useParams()
  const { control } = useForm({
    defaultValues: route_values,
    schema: schemaValidationRoutes,
  })

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Edición de ruta", href: `/routes/${route_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de ruta" string_id={route_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormRoute control={control} />
      </form>
    </div>
  )
}

export default EditScreen
