import { SOURCE_TYPE } from "components/FormEntities/FormSource/source.config"
import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import { format_date } from "./datetime_helper"

// OOM
export const get_data_table_orders = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((order: any) => ({
    id: order?.id,
    channel: order?.channel,
    currentStatus: order?.currentStatus?.tag,
    shippingType: order?.shippingType,
  }))
}

export const get_data_table_sgs = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((sg: any) => ({
    id: sg?.id,
    channel: sg?.channel,
    orderId: sg?.orderId,
    currentStatus: sg?.currentStatus?.tag,
    shippingType: sg?.shippingType,
  }))
}

// OLM
export const get_data_table_sources = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "nombre", "tipo", "habilitado", "ranking", "Recoger en tienda", "Stock de seguridad"]
  return [
    columns,
    data.map((source: any) => ({
      id: source?.id,
      name: source?.name,
      type: SOURCE_TYPE.find((src: any) => src.value === source?.type)?.label,
      enabled: source?.enabled ? "Si" : "No",
      ranking: JSON.stringify(source?.ranking),
      storePickup: source?.storePickup ? "Si" : "No",
      safety: source?.safety,
    })),
  ]
}

export const get_data_table_couriers = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "nombre", "Alias", "Tipo de envío"]

  return [
    columns,
    data.map((courier: any) => ({
      id: courier?.id,
      name: courier?.name,
      alias: courier?.alias,
      shippingType: courier?.shippingType.map(
        (_ship: string) => ` ${SHIPPING_TYPES.find((ship: any) => ship.value === _ship)?.label} -`,
      ),
    })),
  ]
}

export const get_data_table_locations = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((location: any) => ({
    id: location?.id,
    criteria0: location?.criteria0,
    criteria1: location?.criteria1,
    criteria2: location?.criteria2,
    criteria3: location?.criteria3,
  }))
}

export const get_data_table_routes = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "habilitado"]

  return [
    columns,
    data.map((route: any) => ({
      id: route?.id,
      status: route?.status ? "Si" : "No",
    })),
  ]
}

export const get_data_table_coverages = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((coverage: any) => ({
    id: coverage?.id,
    courierId: coverage?.courierId,
    routeId: coverage?.routeId,
  }))
}

export const get_data_table_areas = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((area: any) => ({
    id: area?.id,
  }))
}

export const get_data_table_schedules = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "tipo", "Habilitado", "tipo de entrega"]
  return [
    columns,
    data.map((schedule: any) => ({
      id: schedule?.id,
      type: schedule?.type,
      status: schedule?.status ? "Si" : "No",
      deliveryType: schedule?.deliveryType,
    })),
  ]
}

export const get_data_table_capacity = (data: any) => {
  if (!Boolean(data)) return []
  const columns = ["id", "tipo", "Fecha"]
  return [
    columns,
    data.map((capacity: any) => ({
      id: capacity?.id,
      type: capacity?.type,
      date: format_date(capacity?.date),
    })),
  ]
}

// OIM
export const get_data_table_channels = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((channel: any) => ({
    id: channel?.id,
    name: channel?.name,
  }))
}

export const get_data_table_stock = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((stock: any) => ({
    id: stock?.id,
    name: stock?.name,
  }))
}

export const get_data_table_items = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((item: any) => ({
    sku: item?.sku,
    name: item?.name,
    storePickup: JSON.stringify(item?.storePickup),
  }))
}
