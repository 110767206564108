import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationSources = yup.object().shape({
  // 1
  id: yup.string().required("required-field"),
  name: yup.string().required("required-field"),
  alias: yup.string().required("required-field"),
  type: yup_object_select.required("required-field"),
  physicalLink: yup.string().when("type", ([type]: any) => {
    if (type?.value === "virtual") {
      return yup.string().required("required-field")
    }
    return yup.string().nullable()
  }),
  // 2
  location: yup
    .array(
      yup.object().shape({
        key: yup.string().required("required-field"),
        type: yup_object_select.required("required-field"),
        value: yup.mixed().required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-location")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 3
  safety: yup.number().integer().min(0).required("required-field"),
  ranking: yup.number().integer().min(0).required("required-field"),
  storePickup: yup.boolean(),
  enabled: yup.boolean(),
  // 4
  contact: yup.object().shape({
    name: yup.string().required("required-field"),
    phone: yup.string().required("required-field"),
  }),
  // 5
  custom: yup
    .array(
      yup.object().shape({
        key: yup.string().required("required-field"),
        type: yup_object_select.required("required-field"),
        value: yup.mixed().required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-location")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 6
  volumeCapacity: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-day")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        overCapacity: yup.number().integer().min(0).required("required-field"),
        capacity: yup.number().integer().min(0).required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 7
  dispatchCapacity: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-shippingType")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        overCapacity: yup.number().integer().min(0).required("required-field"),
        capacity: yup.number().integer().min(0).required("required-field"),
        productType: yup.string().required("required-field"),
        deliveryType: yup_object_select.required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 8
  schedule: yup
    .array(
      yup.object().shape({
        shippingType: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-shippingType")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        productType: yup.string().required("required-field"),
        deliveryType: yup_object_select.required("required-field"),
        leadtime: yup.object().shape({
          value: yup.number().integer().min(0).required("required-field"),
          unit: yup_object_select.required("required-field"),
        }),
        slots: yup
          .array(
            yup.object().shape({
              from: yup_object_select.required("required-field"),
              to: yup_object_select.required("required-field"),
              label: yup.string().required(),
            }),
          )
          .min(1, "you-must-enter-at-least-1-shippingType")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 9
  crossdock: yup
    .array(
      yup.object().shape({
        value: yup.number().integer().min(0).required("required-field"),
        unit: yup_object_select.required("required-field"),
        productType: yup.string().required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 10
  workingSessions: yup
    .array(
      yup.object().shape({
        // capacity: yup.number().integer().min(0).required("required-field"),
        productType: yup.string().required("required-field"),
        capacity: yup.number().integer().min(0).required("required-field"),
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-shippingType")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        from: yup_object_select,
        to: yup_object_select,
        enable_locktime: yup.boolean().nullable(),
        lockTime: yup.object().shape({
          from: yup.mixed(),
          to: yup.mixed(),
        }),
      }),
    )
    .min(1, "you-must-enter-at-least-1-source")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 11
  workingTime: yup
    .array(
      yup.object().shape({
        daysOfWeek: yup
          .array(yup_object_select)
          .min(1, "you-must-enter-at-least-1-day")
          .required("required-field")
          .typeError("you-must-enter-at-least-one-value"),
        from: yup_object_select.required("required-field"),
        to: yup_object_select.required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-workingday")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  // 12
  calendarSettings: yup.object().shape({
    timezone: yup_object_select.required("required-field"),
    nonWorkingDays: yup
      .array(yup_object_select)
      .min(1, "you-must-enter-at-least-1-day")
      .required("required-field")
      .typeError("you-must-enter-at-least-one-value"),
    nonWorkingDates: yup.array(yup.string()),
  }),
})

export default schemaValidationSources
