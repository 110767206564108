import cn from "classnames"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import get_client_color from "utils/get_client_color"
// interface IButtonGroup {}

const ButtonGroup = () => {
  const _color = get_client_color()
  const navigate = useNavigate()

  const handle_back = useCallback(() => navigate(-1), [navigate])

  return (
    <div className="flex flex-row gap-2 font-semibold">
      <button
        type="button"
        onClick={handle_back}
        className={cn(
          "w-full lg:w-48 p-2 text-sm text-md border hover:opacity-80 rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
          `border_${_color}`,
          `text_${_color}`,
        )}
      >
        Cancelar
      </button>
      <button
        type="submit"
        className={cn(
          "w-full lg:w-48 p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
          `bg_${_color}`,
        )}
      >
        Guardar
      </button>
    </div>
  )
}

export default ButtonGroup
