import { axiosClientMiddleware } from "config/axios/AxiosClient"
import { AxiosResponse, CancelToken } from "axios"
import OMNIX_MODULE from "constants/OMNIX_MODULE"

interface IResponseService<TResult> {
  transactionId?: string
  code: number
  message: TResult
}

export const authorizerService = async (
  body: any,
  // cancelToken: CancelToken,
): Promise<AxiosResponse<any>> => {
  const response = await axiosClientMiddleware.post<IResponseService<any>>("osm/security/oauth2/token", body, {
    headers: { "Content-Type": "application/json" },
    // cancelToken,
  })

  return response
}

export const post_middleware_query = async (
  body: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.post<IResponseService<any>>("query", body, {
    headers: { "Content-Type": "application/json", Authorization: token },
    cancelToken,
  })

  return response
}

export const post_middleware_osrm_search = async (
  entity: string,
  body: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.post<IResponseService<any>>(`osrm/search/${entity}`, body, {
    headers: { "Content-Type": "application/json", Authorization: token },
    cancelToken,
  })

  return response
}

export const post_middleware_query_items = async (
  body: any,
  channel: string,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.post<IResponseService<any>>(
    `orch/inventory/item/all/channel/${channel}?allProperties=true`,
    body,
    {
      headers: { "Content-Type": "application/json", Authorization: token },
      cancelToken,
    },
  )

  return response
}

// Call API Developers -> https://developers.omnixsystem.com/

export const get_module_entity_action_middleware = async (
  group: OMNIX_MODULE,
  endpoint: string,
  params: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.get<IResponseService<any>>(`${group}/${endpoint}`, {
    params,
    headers: { "Content-Type": "application/json", Authorization: token },
    // cancelToken,
  })

  return response
}

export const post_module_entity_action_middleware = async (
  group: OMNIX_MODULE,
  endpoint: string,
  body: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.post<IResponseService<any>>(`${group}/${endpoint}`, body, {
    headers: { "Content-Type": "application/json", Authorization: token },
    // cancelToken,
  })

  return response
}

export const put_module_entity_action_middleware = async (
  group: OMNIX_MODULE,
  endpoint: string,
  body: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.put<IResponseService<any>>(`${group}/${endpoint}`, body, {
    headers: { "Content-Type": "application/json", Authorization: token },
    // cancelToken,
  })

  return response
}

export const delete_module_entity_action_middleware = async (
  group: OMNIX_MODULE,
  endpoint: string,
  params: any,
  token: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const response = await axiosClientMiddleware.delete<IResponseService<any>>(`${group}/${endpoint}`, {
    params,
    headers: { "Content-Type": "application/json", Authorization: token },
    // cancelToken,
  })

  return response
}
