export const CAPACITY_TYPE = [
  { id: 1, label: "Volumen", value: "volume" },
  { id: 2, label: "Despacho", value: "dispatch" },
]

export const ENTITY_TYPE = [
  { id: 1, label: "Source", value: "source" },
  { id: 2, label: "Courier", value: "courier" },
]

export const parsed_body_to_send = (_data: any) => {
  const { dateFrom, dateTo, maxCapacity, type, entityType, entityIds, productType, deliveryType } = _data

  let data = {
    dateFrom,
    dateTo,
    maxCapacity: Number(maxCapacity),
    type: type?.value,
    entityType: entityType?.value,
    entityIds: entityIds?.map((ent: any) => ent?.value),
    productType: productType ? productType?.split(",") : [],
    deliveryType: deliveryType?.value,
  }

  return data
}
