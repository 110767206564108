import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationLocation = yup.object().shape({
  id: yup.string().required("required-field"),
  criteria0: yup.string().required("required-field"),
  criteria1: yup.string().required("required-field"),
  criteria2: yup.string().required("required-field"),
  criterias: yup
    .array(
      yup.object().shape({
        type: yup_object_select.required(),
        value: yup.mixed().required(),
      }),
    )
    .min(1, "you-must-enter-at-least-1-criteria")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
})

export default schemaValidationLocation
