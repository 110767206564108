import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationSchedule from "utils/yup/schedule_schema"
import { useFieldArray } from "react-hook-form"
import schedule_values from "utils/forms_default/schedule_values"
import { useParams } from "react-router-dom"
import { FormSchedule } from "components/FormEntities"
import { parsed_body_to_send, parsed_data_to_form } from "components/FormEntities/FormSchedule/schedule.config"
import { useEffect } from "react"
import useScheduleResources from "hooks/entities/useScheduleResources"

const EditScreen = () => {
  const { schedule_id }: any = useParams()
  const { get_schedule_action } = useScheduleResources()
  const { control, errors, handleSubmit, watch, reset } = useForm({
    defaultValues: schedule_values,
    schema: schemaValidationSchedule,
  })
  const slots_field_form = useFieldArray({ control, name: "slots" })
  const delivery_slots_field_form = useFieldArray({ control, name: "deliverySlots" })
  const rules_field_form = useFieldArray({ control, name: "rules" })
  const working_sessions_field_form = useFieldArray({ control, name: "sourceDefaultSettings.workingSessions" })

  useEffect(() => {
    const load_schedule_on_form = async () => {
      const res = await get_schedule_action({ schedule_id })
      reset(parsed_data_to_form(res?.data?.message))
      console.log("res", res?.data)
    }
    load_schedule_on_form()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule_id])

  const on_submit = (data: any) => {
    const body = parsed_body_to_send(data)
    console.log("body", body)
    console.log("watch", watch())
  }

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de agendamiento", href: "/schedule" },
          { name: "Edición de agendamiento", href: `/schedule/${schedule_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de agendamiento" string_id={schedule_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormSchedule
          is_edit
          control={control}
          errors={errors}
          watch={watch}
          slots_field_form={slots_field_form}
          delivery_slots_field_form={delivery_slots_field_form}
          rules_field_form={rules_field_form}
          working_sessions_field_form={working_sessions_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
