import cn from "classnames"
import {Link} from "react-router-dom"
import { FaPlus } from "react-icons/fa6"
import get_client_color from "utils/get_client_color"

interface IButtonListNavigation {
  label: string
  route: string
  className?: string
}

const ButtonListNavigation = ({ route, label, className }: IButtonListNavigation) => {
  const _color = get_client_color()
  
  return (
    <Link
      to={route}
      className={cn(
        "w-auto flex flex-row items-center justify-center gap-2 py-1.5 px-4 font-semibold text-sm rounded-md text-white transform transition duration-500 hover:scale-105 whitespace-nowrap",
        `bg_${_color}`,
        className,
      )}
    >
      <p>{label}</p>
      <FaPlus />
    </Link>
  )
}

export default ButtonListNavigation
