import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import { format_date } from "helpers/datetime_helper"

// OOM
export const get_data_table_orders = (data: any) => {
  if (!Boolean(data)) return []

  const columns = ["id", "Canal", "EOC", "Estado actual", "Tipo de envío", "Tipo de entrega", "Fecha de entrega"]

  return [
    columns,
    data.map((order: any) => ({
      id: order?.id,
      channel: order?.channel,
      salesChannelId: order?.salesChannelId,
      currentStatus: order?._omnixStateMachine?.currentState?.state?.name,
      shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === order?.shippingType)?.label,
      deliveryType: order?.deliveryType,
      estimatedDeliveryDate: format_date(order?.estimatedDeliveryDate),
    })),
  ]
}

export const get_data_table_sgs = (data: any) => {
  if (!Boolean(data)) return []

  const columns = ["id", "Canal", "EOC", "Id de órden", "Estado actual", "Tipo de envío", "Source"]

  return [
    columns,
    data.map((sg: any) => ({
      id: sg?.id,
      channel: sg?.channel,
      salesChannelId: sg?.salesChannelId,
      orderId: sg?.orderId,
      currentStatus: sg?._omnixStateMachine?.currentState?.state?.name,
      shippingType: SHIPPING_TYPES.find((ship: any) => ship.value === sg?.shippingType)?.label,
      source: sg?.source?.name
    })),
  ]
}

export const get_data_table_items = (data: any) => {
  if (!Boolean(data)) return []
  return data.map((item: any) => ({
    sku: item?.sku,
    name: item?.name,
    storePickup: JSON.stringify(item?.storePickup),
  }))
}
