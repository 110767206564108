import { Breadcrumb, TitleScreen, ButtonGroup } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationCouriers from "utils/yup/couriers_schema"
import courier_values from "utils/forms_default/courier_values"
import { useFieldArray } from "react-hook-form"
import { FormCourier } from "components/FormEntities"
import { useParams } from "react-router-dom"
import { parsed_body_to_send } from "components/FormEntities/FormCourier/courier.config"

const EditScreen = () => {
  const { courier_id } = useParams()
  const { control, errors, handleSubmit, setValue } = useForm({
    defaultValues: courier_values,
    schema: schemaValidationCouriers,
  })

  const volume_capacity_field_form = useFieldArray({ control, name: "volumeCapacity" })
  const dispatch_capacity_field_form = useFieldArray({ control, name: "dispatchCapacity" })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })

  const on_submit = (data: any) => {
    const body = parsed_body_to_send(data)
    console.log("body", body)
  }

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de courier", href: "/couriers" },
          { name: "Edición de courier", href: `/couriers/${courier_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de courier" string_id={courier_id} />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCourier
          control={control}
          errors={errors}
          setValue={setValue}
          volume_capacity_field_form={volume_capacity_field_form}
          dispatch_capacity_field_form={dispatch_capacity_field_form}
          working_time_field_form={working_time_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
