import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import { useFieldArray } from "react-hook-form"
import coverage_values from "utils/forms_default/coverage_values"
import schemaValidationCoverages from "utils/yup/coverages_schema"
import { FormCoverage } from "components/FormEntities"
import { useParams } from "react-router-dom"
import { parsed_body_to_send } from "components/FormEntities/FormCoverage/coverage.config"

const NewScreen = () => {
  const { coverage_id } = useParams()
  const { control, errors, handleSubmit } = useForm({
    defaultValues: coverage_values,
    schema: schemaValidationCoverages,
  })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })
  const cost_field_form = useFieldArray({ control, name: "cost" })

  const on_submit = (data: any) => {
    const body = parsed_body_to_send(data)
    console.log("body", body)
  }

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de coberturas", href: "/coverages" },
          { name: "Edición de cobertura", href: "/coverages/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de cobertura" string_id={coverage_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCoverage
          control={control}
          errors={errors}
          working_time_field_form={working_time_field_form}
          cost_field_form={cost_field_form}
        />
      </form>
    </div>
  )
}

export default NewScreen
