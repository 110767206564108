export const SIMO_VAR = "USER_SIMO"
export const GROUP_SERVICE_SERIES = "GROUP_SERVICE_SERIES"

export const auth0ClientId_dev = process.env.REACT_APP_AUTH0_CLIENT_ID_DEV
export const auth0ClientSecret_dev = process.env.REACT_APP_AUTH0_CLIENT_SECRET_DEV
export const auth0ClientId_uat = process.env.REACT_APP_AUTH0_CLIENT_ID_UAT
export const auth0ClientSecret_uat = process.env.REACT_APP_AUTH0_CLIENT_SECRET_UAT
export const auth0Audience_dev = process.env.REACT_APP_AUTH0_AUDIENCE_DEV
export const auth0Audience_uat = process.env.REACT_APP_AUTH0_AUDIENCE_UAT
export const auth0Domain_dev = process.env.REACT_APP_AUTH0_DOMAIN_DEV
export const auth0Domain_uat = process.env.REACT_APP_AUTH0_DOMAIN_UAT
