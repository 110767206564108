export function groupBy(arr: any, key: any) {
  return arr.reduce((acc: any, obj: any) => {
    const group = obj[key]
    if (!acc[group]) {
      acc[group] = []
    }
    acc[group].push(obj)
    return acc
  }, {})
}
