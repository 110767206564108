export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    name,
    alias,
    shippingType,
    deliveryType,
    costStrategy,
    locationStrategy,
    productType,
    status,
    channel,
    criterias,
    dispatchCapacity,
    volumeCapacity,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    // 1
    id,
    name,
    alias: [alias],
    // 2
    shippingType: shippingType ? shippingType?.map((shp: any) => shp?.value) : [],
    deliveryType: deliveryType?.value,
    // 3
    costStrategy: costStrategy ? costStrategy?.map((cst: any) => cst?.value) : [],
    locationStrategy: locationStrategy?.value,
    // 4
    productType: productType && productType?.split(","),
    status,
    channel: channel ? channel?.map((chn: any) => chn?.value) : [],
    // 5
    criterias: criterias && criterias?.split(","),
    // 6
    dispatchCapacity: dispatchCapacity?.map((dp: any) => ({
      daysOfWeek: dp?.daysOfWeek.map((day: any) => day?.value),
      overCapacity: Number(dp?.overCapacity),
      capacity: Number(dp?.capacity),
    })),
    // 7
    volumeCapacity: volumeCapacity?.map((vc: any) => ({
      daysOfWeek: vc?.daysOfWeek.map((day: any) => day?.value),
      overCapacity: Number(vc?.overCapacity),
      capacity: Number(vc?.capacity),
    })),
    // 8
    workingTime: workingTime?.map((wt: any) => ({
      daysOfWeek: wt?.daysOfWeek.map((day: any) => day?.value),
      from: wt?.from?.value,
      to: wt?.to?.value,
    })),
    // 9
    calendarSettings: {
      nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => day?.value) || [],
      nonWorkingDates: calendarSettings?.nonWorkingDates || [],
    },
  }

  return data
}
