import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useScheduleResources from "hooks/entities/useScheduleResources"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"

const DetailScreen = () => {
  const [schedule, set_schedule] = useState<any>()
  const { schedule_id } = useParams()
  const { set_loading_app, get_schedule_action } = useScheduleResources()

  useEffect(() => {
    const get_schedule = async () => {
      try {
        set_loading_app(true)
        const res: any = await get_schedule_action({ schedule_id: schedule_id || "" })
        set_schedule(res?.data?.message)
        set_loading_app(false)
      } catch (error: any) {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_schedule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log("schedule", schedule)

  if (!schedule) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de agendamiento", href: "/schedule" },
          { name: "Detalle de agendamiento", href: `/schedule/${schedule_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de agendamiento #${schedule_id}`} />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de schedule</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.type}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.deliveryType}</p>
            </div>
          </div>
          <CollapseWithDetail title="Reglas" details={schedule?.rules} />
          <CollapseWithDetail title="Slots" details={schedule?.slots} />
          <CollapseWithDetail title="Slots de entrega" details={schedule?.deliverySlots} />
          <CollapseWithDetail title="Configuración por defecto sources" details={schedule?.sourceDefaultSettings} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle del schedule</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Fechas a entregar</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.datesToReturn}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{schedule?.status ? "Habilitado" : "Deshabilitado"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Horario habilitado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">00:00 - 18:00</p>
            </div>
          </div>
          <CollapseWithDetail title="Tipos de envío" details={schedule?.shippingType} />
          <CollapseWithDetail
            title="Configuración por defecto del producto"
            details={schedule?.productDefaultSettings}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
