import { useContext } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import OMNIX_MODULE from "constants/OMNIX_MODULE"
import {
  get_module_entity_action_middleware,
  post_module_entity_action_middleware,
  put_module_entity_action_middleware,
  delete_module_entity_action_middleware,
} from "services/middleware_services"
import Swal from "sweetalert2"

interface IGetCourierParams {
  courier_id: string
  params?: any
}

interface IPostCourierParams {
  body: any
}

const useCouriersResources = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { loading_app, set_loading_app } = useContext(AppContext)

  const get_courier_action = async ({ courier_id, params = {} }: IGetCourierParams) => {
    try {
      return await get_module_entity_action_middleware(
        OMNIX_MODULE.OLM,
        `couriers/${courier_id}`,
        params,
        token,
        newCancelToken(),
      )
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const post_list_courier_action = async ({ body }: IPostCourierParams) => {
    try {
      return await post_module_entity_action_middleware(OMNIX_MODULE.OLM, `couriers/all`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const put_upsert_courier_action = async ({ body }: IPostCourierParams) => {
    try {
      return await put_module_entity_action_middleware(OMNIX_MODULE.OLM, `couriers`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const delete_courier_action = async ({ body }: IPostCourierParams) => {
    try {
      return await delete_module_entity_action_middleware(OMNIX_MODULE.OLM, `couriers`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    get_courier_action,
    post_list_courier_action,
    put_upsert_courier_action,
    delete_courier_action,
    loading_app,
    set_loading_app,
  }
}

export default useCouriersResources
