
import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useRoutesResources from "hooks/entities/useRoutesResources"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"

const DetailScreen = () => {
  const [route, set_route] = useState<any>()
  const { route_id } = useParams()
  const { set_loading_app, get_route_action } = useRoutesResources()

  useEffect(() => {
    const get_route = async () => {
      try {
        set_loading_app(true)
        const res: any = await get_route_action({ route_id: route_id || "" })
        set_route(res?.data?.message)
        set_loading_app(false)
      } catch (error: any) {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_route()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log("route", route)

  if (!route) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Detalle de ruta", href: `/routes/${route_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de ruta #${route_id}`} />
        {/* <p>SourceDetailScreen</p> */}
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[rgb(76,76,76)] px-4 py-3">Id de ruta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{route_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado de la ruta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{route?.status ? "Habilitado" : "Deshabilitado"}</p>
            </div>
          </div>
          <CollapseWithDetail title="Source" details={route?.source} />
          <CollapseWithDetail title="Target" details={route?.target} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Acciones</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
