import { FormSection, Select, Switch, TextField } from "components/Core"
import { Controller } from "react-hook-form"

interface IFormRoute {
  control: any
}

const FormRoute = ({ control }: IFormRoute) => {
  const ROUTE_TYPE = [
    { id: 1, label: "Ubicación", value: "location" },
    { id: 2, label: "Source", value: "source" },
  ]

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection title="Información general" helperText="Información general" number={1}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Id Ruta" onChange={onChange} value={value} />}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Estado de ruta" onChange={onChange} checked={value} />
              )}
            />
          </div>
        </div>
      </FormSection>
      <FormSection title="Source" helperText="Source" number={2}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
            )}
          />
        </div>
      </FormSection>
      <FormSection title="Target" helperText="Target" number={3}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Tipo" options={ROUTE_TYPE} onChange={onChange} value={value} />
            )}
          />
        </div>
      </FormSection>
    </div>
  )
}

export default FormRoute
