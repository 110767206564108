const location_values = {
  id: "",
  criteria0: "",
  criteria1: "",
  criteria2: "",
  criterias: [
    {
      type: "text",
      value: "",
    },
  ],
}

export default location_values
