import { useContext, useEffect, useState } from "react"
import useChannelsResources from "hooks/entities/useChannelsResources"
import useCouriersResources from "hooks/entities/useCouriersResources"
import useSourcesResources from "hooks/entities/useSourcesResources"
import useScheduleResources from "hooks/entities/useScheduleResources"
import { SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import { AppContext } from "store/context/AppContext"

const useAreasScreenUtils = () => {
  const { loading_app, set_loading_app } = useContext(AppContext)
  const [CHANNELS, set_channels] = useState([])
  const [DELIVERY_TYPES, set_delivery_types] = useState([])
  const [SOURCES, set_sources] = useState([])
  const [COURIERS, set_couriers] = useState([])
  const { post_list_channel_action } = useChannelsResources()
  const { post_list_courier_action } = useCouriersResources()
  const { post_list_source_action } = useSourcesResources()
  const { post_list_schedule_action } = useScheduleResources()

  useEffect(() => {
    set_loading_app(true)
    const pagination = { skipRecords: 0, maxRecords: 500 }
    const get_data = async () => {
      try {
        const [res_channels, res_delivery_types, res_sources, res_couriers] = await Promise.all([
          post_list_channel_action(),
          post_list_schedule_action({ body: { ...pagination, project: ["deliveryType"] } }),
          post_list_source_action({ body: { ...pagination, project: ["id", "name"] } }),
          post_list_courier_action({ body: { ...pagination, project: ["id", "name"] } }),
        ])
        set_loading_app(false)

        set_channels(
          res_channels?.data?.message?.map((chl: any) => ({
            id: chl?.id,
            label: `${chl?.id} - ${chl?.name}`,
            value: chl?.id,
          })),
        )
        set_delivery_types(
          res_delivery_types?.data?.message?.records?.map((del: any) => ({
            id: del?.deliveryType,
            label: del?.deliveryType,
            value: del?.deliveryType,
          })),
        )
        set_sources(
          res_sources?.data?.message?.records?.map((src: any) => ({
            id: src?.id,
            label: `${src?.id} - ${src?.name}`,
            value: src?.id,
          })),
        )
        set_couriers(
          res_couriers?.data?.message?.records?.map((cou: any) => ({
            id: cou?.id,
            label: `${cou?.id} - ${cou?.name}`,
            value: cou?.id,
          })),
        )
      } catch (error) {
        set_loading_app(false)
      }
    }
    get_data()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading_app,
    CHANNELS,
    SHIPPING_TYPES,
    DELIVERY_TYPES,
    SOURCES,
    COURIERS,
  }
}

export default useAreasScreenUtils
