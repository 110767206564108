import { Breadcrumb, TitleScreen, Paginator, Table } from "components/Core"
import SearchBarFilter from "components/SearchBarFilter"
import { useEffect, useState } from "react"

import { get_data_table_sgs } from "../../helpers/data_tables"
import usePaginationTable from "../../hooks/usePaginationTable"
import usePostMiddlewareQuery from "../../hooks/usePostMiddlewareQuery"

const ListScreen = () => {
  const { post_middleware_query_action } = usePostMiddlewareQuery()
  const [data, set_data] = useState<any>()
  const [loading_app, set_loading_app] = useState<boolean>(false)

  const { current_data, current_page, handle_page_change, handle_per_page, per_page, total_records, total_pages } =
    usePaginationTable({
      data,
    })

  useEffect(() => {
    const post_req = async () => {
      set_loading_app(true)
      const res = await post_middleware_query_action({
        skipRecords: per_page * current_page,
        maxRecords: per_page,
        entityType: "ShippingGroup",
        filter: {},
      })
      set_data(res?.data?.message)
      set_loading_app(false)
    }
    post_req()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page, per_page])

  const [columns, data_table_sgs] = get_data_table_sgs(current_data)

  return (
    <div className="w-full h-full flex flex-col items-center justify-start pb-8">
      <Breadcrumb data={[{ name: "Listado de grupo de envíos", href: "/sgs" }]} />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title="listado de grupo de envíos" />
        {/* <p>SourceListScreen</p> */}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col lg:flex-row justify-between items-end lg:items-center gap-4 px-6">
          <SearchBarFilter />
        </div>
        <div className="w-full flex flex-col gap-2 px-6">
          <Paginator
            current_page={current_page}
            handle_page_change={handle_page_change}
            per_page={per_page}
            total_records={total_records}
            total_pages={total_pages}
            handle_per_page={handle_per_page}
          />
          <div className="w-full overflow-x-auto">
            <Table>
              <Table.Head>
                {columns.map((key: string) => (
                  <Table.HeadCell>{key}</Table.HeadCell>
                ))}
              </Table.Head>
              <Table.Body>
                {!loading_app && data_table_sgs?.length ? (
                  data_table_sgs.map((sg: any, i: number) => (
                    <Table.Row key={i}>
                      {Object.values(sg).map((value: any, i: number) =>
                        i === 0 ? (
                          <Table.Cell key={i} mainCol>
                            <a href={`/sgs/${value}`} className="text-[#F9004D] underline">
                              {value}
                            </a>
                          </Table.Cell>
                        ) : (
                          <Table.Cell key={i}>{value}</Table.Cell>
                        ),
                      )}
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell>{loading_app ? "Espere..." : "Sin resultados"}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListScreen
