import * as yup from "yup"

const yup_object_select = yup.object().shape({
  id: yup.mixed(),
  label: yup.string(),
  value: yup.string(),
})

const schemaValidationSchedule = yup.object().shape({
  // 1
  id: yup.string().required("required-field"),
  deliveryType: yup.string().required("required-field"),
  shippingType: yup
    .array(yup_object_select)
    .min(1, "you-must-enter-at-least-1-shippingType")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  datesToReturn: yup.number().integer().min(0).required("required-field"),
  // 2
  status: yup.boolean(),
  permit_enable_time: yup.boolean(),
  enableTime: yup.object().when("permit_enable_time", ([permit_enable_time]: any) => {
    if (permit_enable_time) {
      return yup.object().shape({ from: yup_object_select, to: yup_object_select }).required("required-field")
    }
    return yup.mixed().nullable()
  }),
  // 3
  type: yup_object_select.required("required-field"),
  rules: yup.array().when("type", ([type]: any) => {
    if (type?.value === "rules") {
      return yup
        .array(
          yup.object().shape({
            receptionTime: yup.object().shape({
              from: yup_object_select,
              to: yup_object_select,
              daysOfWeek: yup.array(yup_object_select),
            }),
            deliveryTime: yup.object().shape({
              offset: yup.object().shape({
                value: yup.number().integer().min(0),
                unit: yup_object_select,
              }),
              fixedHour: yup_object_select,
              label: yup.string().required("required-field"),
            }),
          }),
        )
        .min(1, "you-must-enter-at-least-1-rule")
        .required("required-field")
        .typeError("you-must-enter-at-least-one-value")
    }
    return yup.mixed()
  }),
  // 4
  slots: yup
    .array(
      yup.object().shape({
        label: yup.string().required("required-field"),
        from: yup_object_select,
        to: yup_object_select,
      }),
    )
    .min(1, "you-must-enter-at-least-1-slot")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  deliverySlots: yup.array(
    yup.object().shape({
      dayOfWeek: yup_object_select,
      from: yup_object_select,
      to: yup_object_select,
      label: yup.string().required("required-field"),
    }),
  ),
  sourceDefaultSettings: yup.object().shape({
    leadtime: yup.object().shape({
      value: yup.number().integer().min(0).required("required-field"),
      unit: yup_object_select.required("required-field"),
    }),
    crossdock: yup.object().shape({
      value: yup.number().integer().min(0).required("required-field"),
      unit: yup_object_select.required("required-field"),
    }),
    workingTime: yup.object().shape({
      from: yup_object_select,
      to: yup_object_select,
    }),
    workingSessions: yup
      .array(
        yup.object().shape({
          // capacity: yup.number().integer().min(0).required("required-field"),
          productType: yup.string().required("required-field"),
          from: yup_object_select,
          to: yup_object_select,
          enable_locktime: yup.boolean(),
          lockTime: yup.object().shape({
            from: yup.mixed(),
            to: yup.mixed(),
          }),
        }),
      )
      .min(1, "you-must-enter-at-least-1-source")
      .required("required-field")
      .typeError("you-must-enter-at-least-one-value"),
  }),
  productDefaultSettings: yup.object().shape({
    leadtime: yup.object().shape({
      value: yup.number().integer().min(0).required("required-field"),
      unit: yup_object_select.required("required-field"),
    }),
    volume: yup.number().integer().min(0).required("required-field"),
    cost: yup.number().integer().min(0).required("required-field"),
  }),
})

export default schemaValidationSchedule
