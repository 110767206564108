import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import { useFieldArray } from "react-hook-form"
import coverage_values from "utils/forms_default/coverage_values"
import schemaValidationCoverages from "utils/yup/coverages_schema"
import { FormCoverage } from "components/FormEntities"
import { parsed_body_to_send } from "components/FormEntities/FormCoverage/coverage.config"
import useCoveragesResources from "hooks/entities/useCoveragesResources"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const NewScreen = () => {
  const navigate = useNavigate()
  const { control, errors, handleSubmit } = useForm({
    defaultValues: coverage_values,
    schema: schemaValidationCoverages,
  })
  const { set_loading_app, put_upsert_coverage_action } = useCoveragesResources()
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })
  const cost_field_form = useFieldArray({ control, name: "cost" })

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      await put_upsert_coverage_action({ body })
      Swal.fire({
        title: "Hecho",
        text: "Cobertura creado",
        icon: "success",
      })
      set_loading_app(false)
      navigate("/coverages")
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de coberturas", href: "/coverages" },
          { name: "Creación de cobertura", href: "/coverages/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de cobertura" />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCoverage
          control={control}
          errors={errors}
          working_time_field_form={working_time_field_form}
          cost_field_form={cost_field_form}
        />
      </form>
    </div>
  )
}

export default NewScreen
