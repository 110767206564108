
import { FaEdit } from "react-icons/fa"
import { Tooltip } from "components/Core"

interface IEditButton {
  onClick: () => void
  label?: string
  isLink?: boolean
}

const EditButton = ({ onClick, label = "",  isLink = false}: IEditButton) => {
  return (
    <Tooltip content="Editar">
      <button
        type="button"
        onClick={onClick}
        className="flex underline px-2 py-1 decoration-red-400 cursor-pointer hover:no-underline"
      >
        {isLink &&
          <FaEdit className="text-xl font-semibold text-[#FF0F00]" />
        }
        {label &&
          <span  className="text-[#FF0F00] text-sm font-medium mt-1">{label}</span>
        }
      </button>
    </Tooltip>
  )
}

export default EditButton
